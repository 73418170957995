.online-status {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.online-status.offline {
  background: #ff6060 !important;
}

.online-status.online {
  background: #2ee02e !important;
}


.dashboard-report-type .ant-radio-button-wrapper-checked {
  background: "red" !important;
}

.my-icon {
  vertical-align: 0.1em !important;

}


.change-log-header {
  color: black;
  font-weight: bolder;
  text-transform: uppercase;
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 300px;
  margin-right: 8px;
}

.upload-list-inline .ant-upload-animate-enter {
  animation-name: 'uploadAnimateInlineIn';
}

.upload-list-inline .ant-upload-animate-leave {
  animation-name: 'uploadAnimateInlineOut';
}

.ant-statistic-content {
  font-size: 16px;
}

.member-summary-info .ant-card-head {
  background: #e6e1e1;
}

.disabled-input-field input,
.disabled-input-field select,
.disabled-input-field button {
  pointer-events: none;
  cursor: default;
  color: rgb(15, 84, 15);
}

.rotating {
  animation: rotating 1.5s linear infinite;
}

@keyframes rotating {
  to {
    transform: rotate(360deg);
  }
}