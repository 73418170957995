.loading-black:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: black;
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 black,
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 black,
      .5em 0 0 black;
  }
}


.loading-white:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: white;
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 white;
  }
}


.wave-black {
  display: inline-block;
  position: absolute;
  width: 70px;
  height: 70px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, - 50%)
}

.wave-black:before,
.wave-black:after {
  position: absolute;
  content: '';
  border: 4px solid #000;
  opacity: 1;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, - 50%);
  animation: wave 1.2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.wave-black:after {
  animation-delay: -0.4s;
}

@keyframes wave {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.wave-white {
  display: inline-block;
  position: absolute;
  width: 70px;
  height: 70px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, - 50%)
}

.wave-white:before,
.wave-white:after {
  position: absolute;
  content: '';
  border: 4px solid #FFF;
  opacity: 1;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, - 50%);
  animation: wave 1.2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.wave-white:after {
  animation-delay: -0.4s;
}

@keyframes wave {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.sidebar .nav-link.active {
  color: #FFF;
  background-color: #f4bb0040 !important;
}

.sidebar .nav-link:hover {
  color: #000 !important;
  background-color: #ffc400 !important;
}

.sidebar .nav-link.active .nav-icon {
  color: #FFF !important;
}

.slip-box {
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 10px;
  background: #2f3539;
  color: #fff;
  padding: 5px;
  font-weight: bold;
}

.slip-box-paper {
  background: #e7c595;
  color: black;

}

.slip-box-green {
  background: green;

}

.slip-box-red {
  background: #8f4847;

}

.image-small {
  height: 1rem;
}

.rounded-circle {
  border-radius: 50% !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.new-credit-limit {
  margin-bottom: 6px;
  text-transform: uppercase;
  font-weight: bolder;
  color: #b51313;
}



.MuiIconButton-root:hover {
  background: none !important;
}

.custom-antd-message-class {
  z-index: 10000 !important;
  margin-top: 10vh !important;
}